import Cookies from "js-cookie"
import { methodTypeCreditCard } from "../../core/constants"
import OrderController from "../../core/controllers/OrderController"
import BrowserFunctions from "../../core/functions/BrowserFunctions"
import MetaFunctions from "../../core/functions/MetaFunctions"
import NumberFunctions from "../../core/functions/NumberFunctions"
import {
  TCheckoutAccess,
  TCheckoutInstallmentsContext,
} from "../../core/types/Checkout"
import { IErrorResponse } from "../../core/types/ErrorResponse"
import { ICalculateInstallment } from "../../core/types/Order"
import { IOrderBump } from "../../core/types/OrderBump"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
  TProductTypePayment,
} from "../../core/types/Product"
import { TSaleProduct, TVoucherStatus } from "../../core/types/Sale"
import { TProductVoucherValue } from "../../core/types/Voucher"
import { TLostSale } from "../../core/types/LostSale"
import { numberOnly } from "../../masks/masks"

export type TCalcInstallmentValuesResult = {
  value: number
  subtotal: number
  discount: number
  total: number
}

export type TAddOrRemoveOrderBumpResult = {
  error: string
  newProducts: TSaleProduct[]
  newValue: number
}

const calcInstallmentValues = (
  paymentMethodType: number,
  installments: IProductPricePaymentInstallment[] | undefined,
  numberInstallments: number,
  voucherStatus: TVoucherStatus,
  voucherValue: number | null | undefined,
  voucherValueType: TProductVoucherValue | null | undefined,
  data: IProductPrice | null,
  products: TSaleProduct[]
): TCalcInstallmentValuesResult => {
  if (paymentMethodType === methodTypeCreditCard) {
    const installment = installments?.find(
      (x) => x.installmentNumber === numberInstallments
    )
    if (installment) {
      const discount = NumberFunctions.round(
        voucherStatus === "valid" && voucherValue
          ? voucherValueType === TProductVoucherValue.value
            ? voucherValue
            : (installment.total * voucherValue) / 100
          : 0
      )

      const installmentDiscount = NumberFunctions.round(
        discount / installment.installmentNumber
      )
      const value = NumberFunctions.round(
        installment.value - installmentDiscount
      )
      const subtotal = NumberFunctions.round(installment.total)
      const total = NumberFunctions.round(subtotal - discount)
      return { value, subtotal, discount, total }
    }
  } else if (data) {
    const value = NumberFunctions.round(
      data?.firstPurchasePrice ?? data?.value ?? 0
    )
    const total = NumberFunctions.round(
      value +
        products.reduce(
          (acc, curr) => acc + (curr.firstPurchasePrice ?? curr.value),
          0
        )
    )
    const discount = NumberFunctions.round(
      voucherStatus === "valid" && voucherValue
        ? voucherValueType === TProductVoucherValue.value
          ? voucherValue
          : (total * voucherValue) / 100
        : 0
    )
    return { value, subtotal: total, discount, total: total - discount }
  }

  return { value: 0.0, subtotal: 0.0, discount: 0.0, total: 0.0 }
}

const calcPurchaseNewValue = (
  data: IProductPrice | null,
  products: TSaleProduct[]
): number => {
  return (
    (data?.firstPurchasePrice ?? data?.value ?? 0) +
    products.reduce(
      (acc, curr) => acc + (curr.firstPurchasePrice ?? curr.value),
      0
    )
  )
}

const addOrRemoveOrderBumps = async (
  orderBump: IOrderBump,
  data: IProductPrice | null,
  installment: TCheckoutInstallmentsContext | undefined,
  priceId: string,
  products: TSaleProduct[]
): Promise<TAddOrRemoveOrderBumpResult> => {
  let newValue = data?.firstPurchasePrice || data?.value || 0

  if (orderBump.selected) {
    const orderBumpValue =
      orderBump.productPrice?.firstPurchasePrice ||
      orderBump.productPrice?.value ||
      0
    var installments: IProductPricePaymentInstallment[] = []

    if ((installment?.main ?? []).length > 0) {
      if (!orderBump.installments || orderBump.installments.length <= 0) {
        const response = await OrderController.calculeIsntallments({
          priceId,
          newValue: orderBumpValue,
        })
        const responseError = response as IErrorResponse
        const responseData = response as ICalculateInstallment

        if (responseError.code) {
          return { error: responseError.error, newValue: 0.0, newProducts: [] }
        } else {
          installments = responseData.installments
        }
      } else {
        installments = orderBump.installments ?? []
      }
    }

    products.push({
      id: "",
      productId: orderBump.productPrice?.product?.id ?? "",
      productPriceId: orderBump.productPrice?.id ?? "",
      productName: orderBump.productPrice?.product?.name ?? "",
      productCoverUrl: orderBump.productPrice?.product?.coverUrl ?? "",
      amount: 1,
      value: orderBump.productPrice?.value ?? 0,
      installments,
      typePayment:
        orderBump.productPrice?.product?.typePayment ??
        TProductTypePayment.OneTimePayment,
      frequency: orderBump.productPrice?.frequency,
      firstPurchasePrice: orderBump.productPrice?.firstPurchasePrice,
    })

    newValue = calcPurchaseNewValue(data, products)
    return { error: "", newValue, newProducts: products }
  } else {
    const newProducts = products.filter(
      (product) => product.productPriceId !== orderBump.productPrice?.id
    )

    newValue = calcPurchaseNewValue(data, newProducts)
    return { error: "", newValue, newProducts }
  }
}

const getPageAccess = (
  clientIp: string,
  price: IProductPrice,
  codeId: string | undefined,
  affiliatedCode: string | null | undefined,
  lostSaleCode: string | null | undefined,
  fbclid: string | null | undefined
) => {
  const infos = BrowserFunctions.getInfo()
  const { fbp, fbc } = MetaFunctions.getFbpAndFbc()

  const access: TCheckoutAccess = {
    tenantId: price.tenantId ?? "",
    tenantName: price.tenantName ?? "",
    productId: price.productId ?? "",
    productPriceId: price.id,
    productCode: codeId ?? "",
    productName: price.productName ?? "",
    checkoutModelId: price.checkoutModelId ?? "",
    checkoutModelName: price.checkoutModelName ?? "",
    affiliatedCode: affiliatedCode ?? undefined,
    clientIp,
    userAgent: infos.userAgent,
    browser: infos.browser,
    language: infos.language,
    webdriver: infos.webdriver,
    cookieEnabled: infos.cookieEnabled,
    lostSaleCode,
    pageUrl: window.location.href,
    fbclid,
    fbp,
    fbc,
  }
  return access
}

const identifyFacebookPix = (query: URLSearchParams) => {
  const _fbp = query.get("fbp") ?? query.get("_fbp") ?? ""
  const _fbc = query.get("fbc") ?? query.get("_fbc") ?? ""
  const _fbclid = query.get("fbclid") ?? query.get("_fbclid") ?? ""
  Cookies.set("_apfbp", _fbp)
  Cookies.set("_apfbc", _fbc)
  Cookies.set("_apfbclid", _fbclid)
}

const getLostSale = (
  lostSaleCodeId: string | undefined,
  productPriceId: string | undefined,
  affiliatedCode: string | null | undefined,
  name: string,
  email: string | undefined,
  phone: string | undefined,
  zipCode: string | undefined,
  pageAccessId: string | null | undefined,
  clientIp: string | null | undefined
) => {
  const infos = BrowserFunctions.getInfo()
  const lostSale: TLostSale = {
    codeId: lostSaleCodeId,
    productPriceId,
    affiliatedCode,
    name: name,
    email: email?.trim().toLowerCase(),
    phone: numberOnly(phone),
    cep: numberOnly(zipCode),
    pageAccessId,
    clientIp,
    browser: infos.browser,
  }
  return lostSale
}

const CheckoutPageFunctions = {
  calcInstallmentValues,
  calcPurchaseNewValue,
  addOrRemoveOrderBumps,
  getPageAccess,
  identifyFacebookPix,
  getLostSale,
}

export default CheckoutPageFunctions
