import { Box, Button, Stack, Typography } from "@mui/material"
import { useContext } from "react"
import { CheckoutContext } from "../../../../core/contexts/CheckoutContext"

const CheckoutButtonCommit4 = () => {
  const { isPurposeCardUpdate } = useContext(CheckoutContext)

  const textButton = isPurposeCardUpdate()
    ? "Atualizar Dados do Cartão"
    : "Pagar e Receber Agora"

  return (
    <Stack
      direction={"column"}
      alignContent={"center"}
      alignItems={"center"}
      sx={{ width: "100%", pt: 2 }}
    >
      <Button
        fullWidth
        id="buttonCommit"
        type="submit"
        variant="contained"
        style={{
          height: 50,
          textTransform: "none",
          border: "0px solid black",
          color: "white",
          backgroundColor: "#1C52BD",
        }}
        sx={{ fontWeight: 700, fontSize: "14px", lineHeight: "24px" }}
        data-testid="buttonCommit"
      >
        <Typography fontWeight={500}>{textButton}</Typography>
      </Button>

      <Box sx={{ marginTop: 3, marginBottom: 1 }}>
        <img src="/assets/celetusLogo/celetus_grayLogo.png" alt="" />
      </Box>
    </Stack>
  )
}

export default CheckoutButtonCommit4
