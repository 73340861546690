import { Container, Paper, Stack, Typography } from "@mui/material"

const CheckoutError = () => {
  return (
    <Container component="main" maxWidth="md" sx={{ mt: 10, mb: 10 }}>
      <Paper square={true} sx={{ width: "100%", borderRadius: 5 }}>
        <Stack alignContent="center" alignItems="center" sx={{ p: 5 }}>
          <Typography data-cy="productNotFound" data-testid="product-not-found">
            Produto não encontrado
          </Typography>
        </Stack>
      </Paper>
    </Container>
  )
}

export default CheckoutError
