import { CircularProgress, Container, Stack } from "@mui/material"

const CheckoutLoading = () => {
  return (
    <Container component="main" maxWidth="md" sx={{ mt: 10, mb: 10 }}>
      <Stack
        alignContent="center"
        alignItems="center"
        sx={{ width: "100%", p: 5 }}
      >
        <CircularProgress />
      </Stack>
    </Container>
  )
}

export default CheckoutLoading
