import Cookies from "js-cookie"
import { numberOnly } from "../../masks/masks"
import {
  methodTypeBoleto,
  methodTypePix,
  VOUCHER_QUERY_NAMES,
} from "../constants"
import {
  CHECKOUT_LAYOUT_ALL,
  TCheckoutComponent,
  TCheckoutLayout,
  TCheckoutLayoutComponent,
  TCheckoutOrderAttempt,
} from "../types/Checkout"
import { IOrderCharge, IOrderResponse } from "../types/Order"
import {
  IProductPrice,
  TPriceAffiliated,
  TProductPixel,
} from "../types/Product"
import { TSale, TSaleItemStorage, TSaleStorage } from "../types/Sale"
import { TMetaUser } from "../types/Meta"
import Convertions from "./convertions"
import { addDays, addMinutes, diffMinutes, getMaxDate } from "./dateTime"
import {
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentType,
} from "../types/CustomCheckout"

const getSaleDefault = (
  query: URLSearchParams,
  produtctPriceId: string | undefined,
  onlyProducer: boolean,
  subscriptionCodeId: string | null | undefined
): TSale => {
  const purpose = query.get("purpose")
  return {
    checkoutOrderId: undefined,
    productPriceId: produtctPriceId ?? "",
    affiliateCode: query.get("afid"),
    name: query.get("name") ?? "",
    document: "",
    phone: query.get("phone") ?? "",
    email: query.get("email") ?? "",
    emailConfirm: "",
    onlyProducer,
    address: {
      city: "",
      state: "",
      street: "",
      number: "",
      zipCode: query.get("zipcode") || "",
      complement: "",
      reference: "",
      neighborhood: "",
    },
    payment: {
      methodType: 0,
      description: "",
      cardName: "",
      cardNumber: "",
      cardValidate: "",
      cardCCV: "",
      installment: 0,
      ownCard: true,
      value: 0,
      discount: 0,
      total: 0,
      voucherStatus: "none",
      subtotal: 0.0,
    },
    products: [],
    src: query.get("src"),
    sck: query.get("sck"),
    utm_campaign: query.get("utm_campaign"),
    utm_source: query.get("utm_source"),
    utm_medium: query.get("utm_medium"),
    utm_content: query.get("utm_content"),
    utm_term: query.get("utm_term"),
    pageAccessId: null,
    recaptchaToken: null,
    isPurposeCardUpdate: isCheckoutCardUpdate(purpose),
    isCardUpdateOnly: isCheckoutCardUpdateOnly(purpose),
    subscriptionCodeId,
  }
}

const getVoucherAuto = (query: URLSearchParams) => {
  for (let i = 0; i < VOUCHER_QUERY_NAMES.length; i++) {
    const name = VOUCHER_QUERY_NAMES[i]
    const code = query.get(name)
    if (code && code !== "") {
      return code
    }
  }
}

const getLayoutComponentItem = (
  component: TCheckoutComponent,
  components?: TCheckoutLayoutComponent[]
) => {
  if (!components || components.length <= 0) {
    return undefined
  }
  return components.find((c) => c.checkoutComponent === component)
}

const getLayoutComponent = (
  component: TCheckoutComponent,
  components?: TCheckoutLayoutComponent[]
) => {
  const comp = getLayoutComponentItem(component, components)
  if (comp) {
    return comp.componentStatus
  }
  return TCheckoutLayout.None
}

const isNewLayoutCoverImage = (components?: TCheckoutLayoutComponent[]) => {
  return [TCheckoutLayout.Layout4, TCheckoutLayout.Layout5].includes(
    getLayoutComponent(TCheckoutComponent.CoverImage, components)
  )
}

const isLayout5ModelE = (components?: TCheckoutLayoutComponent[]) => {
  return [TCheckoutLayout.Layout5].includes(
    getLayoutComponent(TCheckoutComponent.CoverImage, components)
  )
}

const isStopWatchFixedTop = (list: TCustomCheckoutComponentItem[]) => {
  return (
    list.filter(
      (l) => l.type === TCustomCheckoutComponentType.stopWatch && l.fixedTop
    ).length > 0
  )
}

const getQuerySuccessPage = (
  productCodeId: string,
  productPrice: IProductPrice,
  priceAffiliated: TPriceAffiliated,
  sale: TSale,
  result: IOrderResponse,
  onlyProducer: boolean,
  charge: IOrderCharge
) => {
  var query = `?url=${result.urlRedirect || ""}&pcid=${productCodeId}&afid=${
    sale.affiliateCode ?? ""
  }`
  query += `&coid=${result.checkoutOrderId}&ocode=${result.orderCode}&pay=${
    result.paymentType || ""
  }&charge=${result.chargeId || ""}`
  query += `&sname=${priceAffiliated.sellerName}&semail=${
    priceAffiliated.sellerEmail
  }&opr=${onlyProducer ? "y" : "n"}`

  if (result.paymentType === methodTypePix) {
    query = `${query}&code=${charge.pixQrCode}&codeurl=${charge.pixQrUrl}`
  } else if (result.paymentType === methodTypeBoleto) {
    query = `${query}&code=${charge.boletoCodeBar}&codeurl=${charge.boletoCodeBarUrl}&codepdf=${charge.boletoPDF}`
  }

  query = `${query}&value=${productPrice.value || 0}`
  if (priceAffiliated.pixels && priceAffiliated.pixels.length > 0) {
    const pixels = JSON.stringify(priceAffiliated.pixels)
    query = `${query}&pixels=${pixels}`

    const items: TSaleItemStorage[] = []
    items.push({
      id: productPrice.id,
      code: productPrice.codeId ?? "",
      quantity: 1,
      name: productPrice.productName ?? "",
      value: productPrice.value ?? 0,
    })

    sale.products.map((product) => {
      items.push({
        id: product.productPriceId,
        code: "",
        quantity: product.amount,
        name: product.productName,
        value: product.value,
      })
    })

    if (items && items.length > 0) {
      query = `${query}&items=${JSON.stringify(items)}`
    }
  }

  query += `&cemail=${sale.email}&cname=${sale.name}&cphone=${
    sale.phone
  }&ccity=${sale.address.city}&cstate=${sale.address.state}&czipcode=${
    sale.address.zipCode
  }&ccountry=BR&cdoc=${numberOnly(sale.document)}`
  query += `&pname=${productPrice.productName}&gtm=${
    productPrice.googleTagManagerId ?? ""
  }`
  return query
}

const getSaleStorageByQuery = (query: URLSearchParams) => {
  const productCode = query.get("pcid") ?? ""
  const affiliatedCode = query.get("afid") ?? ""
  const redirectUrl = query.get("url") ?? ""
  const sellerName = query.get("sname") ?? ""
  const sellerEmail = query.get("semail") ?? ""
  const onlyProducer = query.get("opr") === "y" ? true : false

  const checkoutOrderId = query.get("coid") ?? ""
  const orderCode = query.get("ocode") ?? ""
  const paymentType = Number(query.get("pay") ?? "0")
  const chargeId = query.get("charge") ?? ""
  const code = query.get("code") ?? ""
  const codeUrl = query.get("codeurl") ?? ""
  const codePdf = query.get("codepdf") ?? ""

  const productValue = Number(query.get("value") ?? 0)
  const items = query.get("items") ?? ""
  const pixels = query.get("pixels") ?? ""

  const customerEmail = query.get("cemail") ?? ""
  const customerName = query.get("cname") ?? ""
  const customerPhone = query.get("cphone") ?? ""
  const customerCity = query.get("ccity") ?? ""
  const customerState = query.get("cstate") ?? ""
  const customerZipCode = query.get("czipcode") ?? ""
  const customerCountry = query.get("ccountry") ?? ""
  const customerDocument = query.get("cdoc") ?? ""

  const productName = query.get("pname") ?? ""
  const googleTagManagerId = query.get("gtm") ?? ""

  const saleStorage: TSaleStorage = {
    productCode,
    affiliatedCode,
    redirectUrl,
    sellerName,
    sellerEmail,
    onlyProducer,
    checkoutOrderId,
    orderCode,
    paymentType,
    chargeId,
    code,
    codeUrl,
    codePdf,
    productValue,
    productName,
    googleTagManagerId,
    items: items === "" ? [] : (JSON.parse(items) as TSaleItemStorage[]),
    pixels: pixels === "" ? undefined : (JSON.parse(pixels) as TProductPixel[]),
    customer: {
      email: customerEmail,
      name: customerName,
      phone: customerPhone,
      city: customerCity,
      state: customerState,
      zipCode: customerZipCode,
      country: customerCountry,
      document: customerDocument,
    },
  }

  return saleStorage
}

const getUrlQueryThankyouPage = (url: string, saleStorage: TSaleStorage) => {
  if (!url || url === null || url === "") {
    return ""
  }

  let link = `${url}${url.includes("?") ? "&" : "?"}`
  link += `sfid=${saleStorage.checkoutOrderId}`
  if (saleStorage.onlyProducer) {
    link += "&opr=y"
  }
  return link
}

const setUserCookie = (
  customerEmail: string,
  customerName: string,
  customerPhone: string,
  customerDocument: string,
  customerCity: string,
  customerState: string,
  customerZipCode: string
) => {
  const names = customerName.toLowerCase().split(" ")
  const firstName =
    names.length > 0
      ? names.map((value, index) =>
          index < names.length - 1 ? Convertions.encryptSha256(value) : ""
        )[0]
      : ""
  const lastName =
    names.length > 1 ? Convertions.encryptSha256(names[names.length - 1]) : ""

  const data: TMetaUser = {
    em: Convertions.encryptSha256(customerEmail),
    fn: firstName,
    ln: lastName,
    ph: Convertions.encryptSha256(customerPhone),
    ei: Convertions.encryptSha256(customerDocument),
    ct: Convertions.encryptSha256(customerCity),
    st: Convertions.encryptSha256(customerState),
    zp: Convertions.encryptSha256(customerZipCode),
    name: customerName,
  }
  Cookies.set("_cefbu", JSON.stringify(data), { expires: addDays(365) })
}

const getUserCookie = () => {
  const json = Cookies.get("_cefbu")
  if (json) {
    return JSON.parse(json) as TMetaUser
  }
  return undefined
}

const addAttemptsCookie = (codeId: string) => {
  const key = codeId.toLocaleLowerCase()
  const createdDate = new Date()

  let cookie = getAttemptsCookie()
  if (cookie) {
    let item = cookie[key]
    if (item && item.crs) {
      item.crs.push(createdDate)
    } else {
      item = { crs: [createdDate] }
    }
    cookie[key] = item
  } else {
    cookie = { [key]: { crs: [createdDate] } }
  }
  Cookies.set("__celatt", JSON.stringify(cookie), { expires: addMinutes(1) })
}

const getAttemptsCookie = () => {
  const json = Cookies.get("__celatt")
  if (json) {
    return JSON.parse(json) as TCheckoutOrderAttempt
  }
  return undefined
}

const validAttemptsCookie = (codeId: string) => {
  const key = codeId.toLocaleLowerCase()
  const cookie = getAttemptsCookie()
  if (cookie) {
    const item = cookie[key]
    if (item && item.crs) {
      return item.crs.length < 3
    }
  }
  return true
}

const addAttemptsStorage = (codeId: string) => {
  try {
    const key = codeId.toLocaleLowerCase()
    const createdDate = new Date()

    let attempts = getAttemptsStorage()
    if (attempts) {
      let item = attempts[key]
      if (item && item.crs) {
        const maxDate = getMaxDate(item.crs) ?? createdDate
        const diff = diffMinutes(createdDate, new Date(maxDate))
        if (diff <= 1) {
          item.crs.push(createdDate)
        } else {
          item = { crs: [createdDate] }
        }
      } else {
        item = { crs: [createdDate] }
      }
      attempts[key] = item
    } else {
      attempts = { [key]: { crs: [createdDate] } }
    }

    window.localStorage.setItem("celatt", JSON.stringify(attempts))

    if ((attempts[key]?.crs?.length ?? 0) >= 3) {
      window.localStorage.setItem(
        `cebq${key}`,
        createdDate.getTime().toString()
      )
    } else {
      window.localStorage.removeItem(`cebq${key}`)
    }
  } catch (err) {
    console.error(err)
  }
}

const getAttemptsStorage = () => {
  try {
    const value = window.localStorage.getItem("celatt")
    if (value) {
      return JSON.parse(value) as TCheckoutOrderAttempt
    }
  } catch (err) {
    console.error(err)
  }
  return undefined
}

const validAttemptsStorage = (codeId: string) => {
  const key = codeId.toLocaleLowerCase()
  const cebq = window.localStorage.getItem(`cebq${key}`)
  if (cebq && cebq !== "") {
    const cebqTime = Number(cebq)
    const createdDate = new Date(cebqTime)
    const diff = diffMinutes(new Date(), createdDate)
    return diff > 1
  }
  return true
}

const addAttempts = (codeId: string) => {
  addAttemptsCookie(codeId)
  addAttemptsStorage(codeId)
}

const validAttempts = (codeId: string) => {
  return validAttemptsCookie(codeId) && validAttemptsStorage(codeId)
}

const askReCAPTCHA = (
  productPrice: IProductPrice | null | undefined,
  data: TSale,
  accessPageDate: Date | null
) => {
  /*if (
    !productPrice?.enableCaptcha ||
    data.payment.methodType !== methodTypeCreditCard
  ) {
    return false
  }

  if (accessPageDate) {
    const diff = diffSeconds(new Date(), accessPageDate)
    if (diff <= 30) {
      return true
    }
  }

  const nameComplet = data.name.trim().split(" ")
  if (nameComplet.length <= 2) {
    return true
  }

  return Validations.hasUpperCaseAndNumber(data.email)*/
  return true
}

const getModelByParams = (query: URLSearchParams) => {
  const modelString = numberOnly(query.get("model") ?? "")
  const modelInt = Number(modelString)
  const model = CHECKOUT_LAYOUT_ALL.find((cla) => Number(cla) === modelInt)
  return model
}

const isCheckoutCardUpdate = (purpose: string | null | undefined) => {
  return purpose?.startsWith("card-update") ?? false
}

const isCheckoutCardUpdateOnly = (purpose: string | null | undefined) => {
  return purpose?.startsWith("card-update-only") ?? false
}

const CheckoutFunctions = {
  getSaleDefault,
  getLayoutComponent,
  isNewLayoutCoverImage,
  isLayout5ModelE,
  isStopWatchFixedTop,
  getQuerySuccessPage,
  getSaleStorageByQuery,
  getUrlQueryThankyouPage,
  setUserCookie,
  getUserCookie,
  addAttempts,
  validAttempts,
  askReCAPTCHA,
  getModelByParams,
  getVoucherAuto,
  isCheckoutCardUpdate,
  isCheckoutCardUpdateOnly,
}

export default CheckoutFunctions
