import { TCheckoutLayoutComponent } from "./Checkout"
import { TCustomCheckout } from "./CustomCheckout"
import { IOrderBump } from "./OrderBump"

export enum TProductTypePayment {
  OneTimePayment = 1,
  RecurringSubscription = 2,
}

export enum TProductFrequency {
  Weekly = 1,
  Monthly = 2,
  Bimonthly = 3,
  Quarterly = 4,
  Semester = 5,
  Yearly = 6,
}

export enum TProductLinkDuration {
  Unknown = 0,
  SevenDays = 7,
  ThirtyDays = 30,
  SixtyDays = 60,
  NinetyDays = 90,
  OneHundredAndEightyDays = 180,
  ThreeHundredSixtyFiveDays = 365,
  Eternal = 999,
}

export enum TProductPixelType {
  Facebook = 1,
  GoogleAds = 2,
}

export type IProductPricePayment = {
  id?: string
  methodType: number
  description?: string
  installmentsNumber: number
  installments: IProductPricePaymentInstallment[]
}

export type IProductPricePaymentInstallment = {
  installmentNumber: number
  value: number
  total: number
  isInterestFree: boolean
}

export type TProductPriceAffiliated = {
  affiliatedId: string
  affiliatedCodeId: string
}

export type TProductPriceCustomField = {
  identity: string
  name: string
  isChecked: boolean
}

export type TProductPriceCustomFieldSettings = {
  isCustomFields: boolean
  fields: TProductPriceCustomField[]
}

export type IProductPrice = {
  id: string
  codeId?: string
  productId?: string
  productName?: string
  productImageUrl?: string
  productCoverUrl?: string
  productDescription?: string
  value?: number
  tenantName?: string
  offerName?: string

  typePayment: TProductTypePayment
  frequency?: TProductFrequency
  firstPurchasePrice?: number

  hideCheckoutAddress: boolean
  checkoutModelId?: string

  paymentMethods: IProductPricePayment[]

  checkoutComponents?: TCheckoutLayoutComponent[]
  customCheckout?: TCustomCheckout | null | undefined

  tenantId?: string
  checkoutModelName?: string

  googleTagManagerId?: string | null | undefined

  hasVoucher: boolean
  enableCaptcha: boolean

  customFields?: TProductPriceCustomFieldSettings | null | undefined
}

export type TPriceAffiliated = {
  sellerName?: string
  sellerEmail?: string

  affiliated?: TProductPriceAffiliated | null
  pixels: TProductPixel[]
  googleTagManagerId?: string | null
}

export type IProduct = {
  id: string
  name: string
  description: string
  approvedPageLink: string
  awaitingPaymentPageLink?: string
  awaitingCreditAnalysisPageLink?: string
  memberServiceId?: string
  facebookPixel?: string
  imageCover?: File | undefined
  imageProduct?: File | undefined

  coverUrl?: string
  imageProductUrl?: string

  prices?: IProductPrice[]
}

export type IProductPagination = {
  items: IProduct[]
  page: number
  rowsPerPage: number
  totalPages: number
}

export type IProductSimple = {
  id: string
  name: string
  coverUrl?: string

  typePayment: TProductTypePayment
}

export type TProductPixel = {
  id: string
  pixel: string
  pixelToken?: string
  pixelDomain?: string

  type: TProductPixelType
  enabledDomain: boolean
  cnameVerified: boolean

  selectivePurchase: boolean
  selectiveImmediatePurchase: boolean
  selectiveNonImmediatePurchase: boolean
  visitSalesPage: boolean
}

export type TProductLinkAffiliated = {
  affiliatedCodeId: string
  productPriceCodeIds: Array<string>
  url: string
  duration?: TProductLinkDuration
  otherProducts: boolean
}

export type TProductLinkAffiliatedCookie = {
  cre: number
  afid: string

  /* TRACK */
  src?: string | null
  sck?: string | null
  utm_campaign?: string | null
  utm_source?: string | null
  utm_medium?: string | null
  utm_content?: string | null
  utm_term?: string | null
}

export type TProductLinkPageAccess = {
  affiliatedCodeId: string
  productLinkCodeId?: string | null
  clientIp?: string
  userAgent?: string
  browser?: string
  language?: string
  pageUrl?: string
}
