import { Container, Paper, Stack, Typography } from "@mui/material"
import SuccessIcon from "@mui/icons-material/CheckCircle"
import { grey } from "@mui/material/colors"
import { TSaleStorage } from "../../../core/types/Sale"

interface CardUpdatedSuccessProps {
  sale: TSaleStorage | undefined
}

const CardUpdatedSuccess = ({ sale }: CardUpdatedSuccessProps) => {
  return (
    <Container component={"main"} maxWidth="md">
      <Stack direction="column" spacing={3} sx={{ pt: 8 }}>
        <Paper
          square={true}
          elevation={0}
          sx={{ width: "100%", borderRadius: 5 }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            sx={{ py: { sm: 3, xs: 2 } }}
            spacing={2}
          >
            <Stack sx={{ px: { sm: 5, xs: 2 }, width: "100%" }}>
              <Paper
                square={true}
                elevation={0}
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  backgroundColor: "#1E88E5",
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignContent="center"
                  alignItems="center"
                  sx={{ py: 3, px: { sm: 5, xs: 1 } }}
                >
                  <SuccessIcon sx={{ fontSize: "3.5em", color: "#212121" }} />
                  <Stack direction="column">
                    <Typography variant="h5">
                      Olá {sale?.customer.name}
                    </Typography>
                    <Typography variant="h4" fontWeight={600}>
                      Seu cartão foi alterado com sucesso!
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Stack>
            <Container maxWidth="sm">
              <Typography variant="body1" fontWeight={600} gutterBottom>
                Tudo pronto para continuar aprendendo e alcançando seus
                objetivos!
              </Typography>
              <Typography variant="body1" gutterBottom>
                A partir de agora, as próximas cobranças serão realizadas no
                novo cartão. Caso tenha alguma dúvida, estamos à disposição!
              </Typography>
            </Container>
          </Stack>
        </Paper>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          spacing={2}
        >
          <Container maxWidth="sm">
            <Stack direction="column" width="100%" sx={{ width: "100%" }}>
              <Typography variant="body1" fontWeight={600} gutterBottom>
                Precisa de ajuda?
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 3 }}>
                Fale agora mesmo com o responsável.
              </Typography>

              <Typography variant="body1" gutterBottom>
                <strong>Nome: </strong>
                {sale?.sellerName}
              </Typography>
              <Typography variant="body1">
                <strong>Email: </strong>
                {sale?.sellerEmail}
              </Typography>
            </Stack>
          </Container>
        </Stack>
      </Stack>
    </Container>
  )
}

export default CardUpdatedSuccess
