const scriptKonduto = `
    var visitorID; 
    (function () {
      var period = 300;
      var limit = 20 * 1e3;
      var nTry = 0;
      var intervalID = setInterval(function () {
        var clear = limit / period <= ++nTry;
        if ((typeof (Konduto) !== "undefined") &&
          (typeof (Konduto.getVisitorID) !== "undefined")) {
          visitorID = window.Konduto.getVisitorID();
          localStorage.setItem("visitorID", visitorID);
          clear = true;
        }
        if (clear) {
          clearInterval(intervalID);
        }
      }, period);
    })(visitorID);`

const errorCheckoutAttempts =
  "Parece que algumas informações estão incorretas ou incompletas. Para garantir que sua compra seja processada sem problemas, por favor, revise os detalhes e tente novamente."

const CheckoutConsts = {
  scriptKonduto,
  errorCheckoutAttempts,
}

export default CheckoutConsts
