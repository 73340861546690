import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useLocalStorage } from "../../../core/hooks/useLocalStorage"
import TagFunctions, { ITagsProp } from "../../../core/functions/TagFunctions"
import { TSaleStorage } from "../../../core/types/Sale"
import Hosts from "../../../core/functions/HostFunctions"
import React from "react"
import MetaFunctions from "../../../core/functions/MetaFunctions"
import CheckoutFunctions from "../../../core/functions/CheckoutFunctions"
import { useQuery } from "../../../components/Query/useQuery"
import GoogleFunctions from "../../../core/functions/GoogleFunctions"
import {
  methodTypeCreditCard,
  methodTypeDebitCard,
} from "../../../core/constants"
import { useCheckoutContext } from "../../../core/hooks/useCheckoutContext"
import CardUpdatedSuccess from "./CardUpdatedSuccess"
import OrderSuccess from "./OrderSuccess"

const CheckoutSuccessPage = (): JSX.Element => {
  const { codeId } = useParams<{ codeId?: string }>()

  const query = useQuery()
  const { isFacebookPixelSent, addFacebookPixelSent, isPurposeCardUpdate } =
    useCheckoutContext()

  const googleAds = useRef<ITagsProp[] | null>(null)
  const tagInitialDataLayer = useRef<ITagsProp | null>(null)
  const tagDataLayer = useRef<ITagsProp | null>(null)
  const tagGTM = useRef<ITagsProp | null>(null)

  const [saleStorage] = useLocalStorage("afpa:sale", null)

  const [sale, setSale] = useState<TSaleStorage | undefined>(undefined)
  const [redirectSeconds, setRedirectSeconds] = useState(3)
  const [isRedirect, setIsRedirect] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (saleStorage === null) {
      Hosts.openNavigate(`/${codeId}`, navigate)
      return
    }
    setSale(saleStorage)

    if (!tagInitialDataLayer.current) {
      tagInitialDataLayer.current = GoogleFunctions.initialDataLayer()
    }

    return () => {
      if (tagInitialDataLayer.current) {
        TagFunctions.removeTags(tagInitialDataLayer.current)
      }
    }
  }, [])

  useEffect(() => {
    if (sale && !isPurposeCardUpdate()) {
      if (!isFacebookPixelSent(sale.orderCode, "purchase")) {
        MetaFunctions.addTagPurchase(sale)
        MetaFunctions.sendPurchaseAPI(sale)
        addFacebookPixelSent(sale.orderCode, "purchase")
      }

      if (
        [methodTypeCreditCard, methodTypeDebitCard].includes(sale.paymentType)
      ) {
        if (!googleAds.current) {
          googleAds.current = GoogleFunctions.addTagGoogleAdsPixelPurchase(sale)
        }

        if (!tagDataLayer.current) {
          tagDataLayer.current = GoogleFunctions.addDataLayerPurchase(sale)
        }
        if (!tagGTM.current) {
          tagGTM.current = GoogleFunctions.addGoogleTagManager(
            sale.googleTagManagerId
          )
        }
      }
    }
    return () => {
      if (tagDataLayer.current) {
        TagFunctions.removeTags(tagDataLayer.current)
      }
      if (tagGTM.current) {
        TagFunctions.removeTags(tagGTM.current)
      }
    }
  }, [sale])

  useEffect(() => {
    if (sale && !isPurposeCardUpdate()) {
      if (isRedirect) {
        if (redirectSeconds === 0 && sale.redirectUrl) {
          const url = CheckoutFunctions.getUrlQueryThankyouPage(
            sale.redirectUrl,
            sale
          )
          window.location.href = Hosts.getUrlRedirect(url)
          return
        }

        setTimeout(() => {
          setRedirectSeconds((redirectSeconds) => redirectSeconds - 1)
        }, 1000)
      }

      const redirect = query.get("redirect")
      if (
        redirect !== "no" &&
        sale.redirectUrl !== undefined &&
        sale.redirectUrl !== ""
      ) {
        setIsRedirect(true)
      }
    }
  }, [isRedirect, redirectSeconds, sale])

  if (isPurposeCardUpdate()) {
    return <CardUpdatedSuccess sale={sale} />
  }

  return (
    <OrderSuccess
      sale={sale}
      isRedirect={isRedirect}
      redirectSeconds={redirectSeconds}
    />
  )
}

export default CheckoutSuccessPage
