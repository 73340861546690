import { Routes as Router, Route } from "react-router-dom"
import CheckoutPage from "../pages/Checkout"
import CheckoutSuccessPage from "../pages/Checkout/Success"
import HomePage from "../pages/Home"
import SuccessOrder from "../pages/Checkout/SuccessOrder"
import React from "react"
import { isWidget } from "../core/constants"
import OrderSuccessRedirectPage from "../pages/Checkout/Redirects/OrderSuccessRedirectPage"
import OrderThanksRedirectPage from "../pages/Checkout/Redirects/OrderThanksRedirectPage"
import AffiliatedRedirectPage from "../pages/Checkout/Redirects/AffiliatedRedirectPage"

const Routes = () => {
  if (isWidget()) {
    return (
      <Router>
        <Route index element={<CheckoutPage />} />
        <Route path="/*" element={<CheckoutPage />} />
      </Router>
    )
  }

  return (
    <Router>
      <Route index element={<HomePage />} />

      <Route path="/:codeId" element={<CheckoutPage />} />
      <Route path="/pro/:codeId" element={<CheckoutPage />} />
      <Route path="/:codeId/redirect" element={<AffiliatedRedirectPage />} />
      <Route path="/c/:checkoutOrderCodeId" element={<CheckoutPage />} />
      <Route path="/sub/:subscriptionCodeId" element={<CheckoutPage />} />

      <Route
        path="/:codeId/success/redirect"
        element={<OrderSuccessRedirectPage />}
      />
      <Route
        path="/:codeId/thanks/redirect"
        element={<OrderThanksRedirectPage />}
      />

      <Route path="/:codeId/success" element={<CheckoutSuccessPage />} />
      <Route path="/:codeId/thanks" element={<SuccessOrder />} />
    </Router>
  )
}

export default Routes
