import { Container, Paper, Stack, Typography } from "@mui/material"
import SuccessIcon from "@mui/icons-material/CheckCircle"
import { grey } from "@mui/material/colors"
import { TSaleStorage } from "../../../core/types/Sale"

interface OrderSuccessProps {
  sale: TSaleStorage | undefined
  isRedirect: boolean
  redirectSeconds: number
}

const OrderSuccess = ({
  sale,
  isRedirect,
  redirectSeconds,
}: OrderSuccessProps) => {
  return (
    <Container component={"main"} maxWidth="md">
      {!isRedirect && (
        <Stack direction="column" spacing={3} sx={{ pt: 8 }}>
          <Paper
            square={true}
            elevation={0}
            sx={{ width: "100%", borderRadius: 5 }}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              sx={{ py: { sm: 3, xs: 2 } }}
              spacing={2}
            >
              <Stack sx={{ px: { sm: 5, xs: 2 }, width: "100%" }}>
                <Paper
                  square={true}
                  elevation={0}
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "#1E88E5",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    sx={{ py: 3, px: { sm: 5, xs: 1 } }}
                  >
                    <SuccessIcon sx={{ fontSize: "3.5em", color: "#212121" }} />
                    <Stack direction="column">
                      <Typography variant="h5">
                        Parabéns {sale?.customer.name}
                      </Typography>
                      <Typography variant="h4" fontWeight={600}>
                        Sua compra foi aprovada!
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
              <Container maxWidth="sm">
                <Typography variant="body1" fontWeight={600} gutterBottom>
                  Para acessar seu curso siga os passos abaixo:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  1. Acesse seu email {sale?.customer.email}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  2. Busque pelo login e senha do seu produto{" "}
                  {sale?.productName}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  3. Comece agora mesmo a aprender
                </Typography>

                <Typography variant="body1" fontWeight={600} gutterBottom>
                  Aviso importante
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  Em alguns casos seu email pode acabar se enganando e colocando
                  o nosso email na sua área de SPAM, por isso, verifique sempre
                  essa área.
                </Typography>

                <Paper
                  square={true}
                  elevation={0}
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: grey[100],
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ p: 3 }}
                  >
                    <Typography variant="body1">
                      Seu código de transação:{" "}
                      <strong>{sale?.orderCode}</strong>
                    </Typography>
                  </Stack>
                </Paper>
              </Container>
            </Stack>
          </Paper>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            spacing={2}
          >
            <Container maxWidth="sm">
              <Stack direction="column" width="100%" sx={{ width: "100%" }}>
                <Typography variant="body1" fontWeight={600} gutterBottom>
                  Precisa de ajuda?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  Fale agora mesmo com o responsável pela venda.
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Nome: </strong>
                  {sale?.sellerName}
                </Typography>
                <Typography variant="body1">
                  <strong>Email: </strong>
                  {sale?.sellerEmail}
                </Typography>
              </Stack>
            </Container>
          </Stack>
        </Stack>
      )}

      {isRedirect && (
        <Stack
          direction="column"
          spacing={3}
          alignContent="center"
          alignItems="center"
          sx={{ pt: 8 }}
        >
          <Typography variant="body1" sx={{ mt: 2 }} data-cy="successRedirect">
            Você será redirecionado para a pagina de confirmação em{" "}
            {redirectSeconds} segundos, aguarde...
          </Typography>
        </Stack>
      )}
    </Container>
  )
}

export default OrderSuccess
